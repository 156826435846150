import { css, SerializedStyles } from '@emotion/react'
import * as React from 'react'

import { transitionShowAnimation } from '../../modules/animation'

export default () => {
  const profileStyle: SerializedStyles = css({
    width: 'auto',
    height: 'auto',
    opacity: 0,
    transform: 'translateY(50px)',
    animation: `${transitionShowAnimation} 1s 1s`,
    animationFillMode: 'forwards',
  })

  const calculateYearDifference = (targetDate: Date): number => {
    const currentDate = new Date()
    const yearDiff = currentDate.getFullYear() - targetDate.getFullYear()
    const isAfterTargetMonth = currentDate.getMonth() > targetDate.getMonth()

    if (isAfterTargetMonth) {
      return yearDiff
    }

    return yearDiff - 1
  }

  return (
    <div css={profileStyle}>
      <h2>Profile</h2>
      <dl>
        <dt>名前 ※HN</dt>
        <dd>高木 徹(Takagi Toru)</dd>
        <dt>性別</dt>
        <dd>男</dd>
        <dt>年齢</dt>
        <dd>
          20代後半（社会人{calculateYearDifference(new Date(2018, 4, 1))}年目）
        </dd>
        <dt>職業</dt>
        <dd>Webアプリケーションエンジニア</dd>
        <dt>プログラミング歴</dt>
        <dd>
          {calculateYearDifference(new Date(2014, 4, 1))}
          年（社会人プログラミング歴：
          {calculateYearDifference(new Date(2016, 8, 1))}年）
        </dd>
        <dt>SNS</dt>
        <dd>
          <a href='/' target='_blank'>
            技術ブログ
          </a>{' '}
          /
          <a href='https://twitter.com/TTrpbm' target='_blank'>
            Twitter
          </a>{' '}
          /{' '}
          <a href='http://qiita.com/Kurowasi' target='_blank'>
            Qiita
          </a>{' '}
          /{' '}
          <a href='https://github.com/Toru-Takagi' target='_blank'>
            GitHub
          </a>
          /{' '}
          <a href='https://note.mu/toru_takagi' target='_blank'>
            note
          </a>{' '}
          /
          <a href='mailto:kurowasi2525@gmail.com' target='_blank'>
            Mail
          </a>{' '}
        </dd>
        <dt>プロダクト</dt>
        <dd>
          <a href='https://togello.toru-takagi.dev/' target='_blank'>
            Togello
          </a>
          /
          <a href='https://viro.kurowasi.com' target='_blank'>
            VIRO
          </a>
        </dd>
        <dt>OSS</dt>
        <dd>
          <a href='https://github.com/Toru-Takagi/gopsqlfmt' target='_blank'>
            gopsqlfmt
          </a>
        </dd>
        <dt>Contribute</dt>
        <dd>
          <a href='https://github.com/sqlc-dev/sqlc-gen-kotlin' target='_blank'>
            sqlc-dev/sqlc-gen-kotlin
          </a>
          /
          <a href='https://github.com/formkit/tempo' target='_blank'>
            formkit/tempo
          </a>
          <br />
          /
          <a href='https://github.com/TanStack/form'>
            TanStack/form (Only issue)
          </a>
        </dd>
        <dt>職歴</dt>
        <dd>
          Bachelor of Engineering →{' '}
          <a href='/article/22' target='_blank'>
            一部上場の自社開発
          </a>
          →{' '}
          <a
            href='https://note.com/toru_takagi/n/n09745949df7a'
            target='_blank'
          >
            友人のスタートアップで初期メンバー
          </a>{' '}
          →{' '}
          <a href='/article/32/' target='_blank'>
            メガベンチャー
          </a>
        </dd>
      </dl>
    </div>
  )
}
