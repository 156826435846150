import { css, SerializedStyles } from '@emotion/react'
import * as React from 'react'

import { transitionShowAnimation } from '../../modules/animation'

export default () => {
  const qualificationsStyle: SerializedStyles = css({
    marginTop: '50px',
    width: 'auto',
    height: 'auto',
    opacity: 0,
    transform: 'translateY(50px)',
    animation: `${transitionShowAnimation} 1s 1.5s`,
    animationFillMode: 'forwards',
    dd: {
      '.hight': {},
      '.middle': {
        color: 'rgba(255, 255, 255, 0.6)',
      },
      '.low': {
        color: 'rgba(255, 255, 255, 0.3)',
      },
    },
  })

  return (
    <div css={qualificationsStyle}>
      <h2>Qualifications</h2>
      <dl>
        <dt>フロント</dt>
        <dd>
          <span className='hight'>HTML</span> / <span className='low'>Pug</span>{' '}
          / <span className='hight'>CSS</span> /{' '}
          <span className='middle'>SCSS</span> /{' '}
          <span className='low'>Stylus</span> /{' '}
          <span className='low'>AdminLTE</span> /{' '}
          <span className='hight'>JavaScript</span> /{' '}
          <span className='hight'>TypeScript</span> /{' '}
          <span className='hight'>Vue.js</span> /{' '}
          <span className='hight'>Nuxt.js</span> /{' '}
          <span className='low'>VuePress</span> /{' '}
          <span className='low'>Vuetify</span> /{' '}
          <span className='hight'>React</span> /{' '}
          <span className='low'>Gatsby</span> /{' '}
          <span className='hight'>Next.js</span> /{' '}
          <span className='low'>Riot</span> /{' '}
          <span className='low'>jQuery</span>
        </dd>
        <dt>バックエンド</dt>
        <dd>
          <span className='hight'>Kotlin</span> /{' '}
          <span className='hight'>Go</span> /{' '}
          <span className='middle'>Node.js</span> /{' '}
          <span className='middle'>Express</span> /{' '}
          <span className='hight'>Java</span> /{' '}
          <span className='middle'>PHP</span> /{' '}
          <span className='low'>Laravel</span> /{' '}
          <span className='middle'>CakePHP</span> /{' '}
          <span className='low'>FuelPHP</span> /{' '}
          <span className='low'>WordPress</span> /{' '}
          <span className='hight'>Ruby</span> /{' '}
          <span className='hight'>Ruby on Rails</span> /{' '}
          <span className='middle'>Python</span> /{' '}
          <span className='low'>C</span> / <span className='low'>DxLib</span> /{' '}
          <span className='low'>VisualBasic</span>
        </dd>
        <dt>アプリ</dt>
        <dd>
          <span className='middle'>Flutter</span>
        </dd>
        <dt>データベース</dt>
        <dd>
          <span className='hight'>PostgreSQL</span> /{' '}
          <span className='middle'>MySQL</span> /{' '}
          <span className='middle'>Firestore</span> /{' '}
          <span className='low'>Microsft SQL Server</span> /{' '}
          <span className='low'>Oracle SQL</span> /{' '}
          <span className='middle'>Algolia</span>
        </dd>
        <dt>インフラ</dt>
        <dd>
          <span className='hight'>Docker</span> /{' '}
          <span className='low'>CentOS</span> /{' '}
          <span className='low'>Debian</span> /{' '}
          <span className='middle'>Windows</span> /{' '}
          <span className='hight'>MacOS</span> /{' '}
          <span className='middle'>AWS</span> /{' '}
          <span className='middle'>GCP</span> /{' '}
          <span className='middle'>Firebase</span> /{' '}
          <span className='low'>さくらVPS</span> /{' '}
          <span className='middle'>Netlify</span> /{' '}
          <span className='low'>Vercel</span> /{' '}
          <span className='low'>CircleCI</span> /{' '}
          <span className='low'>Wercker</span> /{' '}
          <span className='middle'>GitHub Actions</span>
        </dd>
        <dt>その他</dt>
        <dd>
          <span className='hight'>Selenium</span> /{' '}
          <span className='hight'>OOP</span> / <span className='low'>AOP</span>{' '}
          / <span className='hight'>DDD</span> /{' '}
          <span className='middle'>microservice</span> /{' '}
          <span className='low'>ImageMagick</span> /{' '}
          <span className='low'>FFMPEG</span>
        </dd>
        <dt>セキュリティ関連</dt>
        <dd>
          <span className='low'>VAddy</span>
          <span className='low'>Dependabot alert</span>
          <span className='low'>Dependabot Security Update</span>
        </dd>
        <dt>バージョン管理</dt>
        <dd>
          <span className='hight'>Git</span> /{' '}
          <span className='middle'>SVN</span> /{' '}
          <span className='middle'>CVS</span>
        </dd>
        <dt>ツール</dt>
        <dd>
          <span className='hight'>Visual Studio Code</span> /{' '}
          <span className='middle'>IntelliJ IDEA</span> /{' '}
          <span className='middle'>Eclipse</span> /{' '}
          <span className='low'>Sublime Text3</span> /{' '}
          <span className='low'>Visual Studio</span> /{' '}
          <span className='low'>XCode</span> /{' '}
          <span className='low'>Android Studio</span> /{' '}
          <span className='hight'>Slack</span> /{' '}
          <span className='hight'>Figma</span> /{' '}
          <span className='low'>Jira</span> /{' '}
          <span className='middle'>Backlog</span> /{' '}
          <span className='low'>esa</span> /{' '}
          <span className='low'>ScrumDo</span> /{' '}
          <span className='middle'>Notion</span>
        </dd>
        <dt>資格</dt>
        <dd>
          Bachelor of Engineering / Oracle Java Programmer Silver SE8 / HTML5
          Professional Certification Level.1 / JSTQB Foundation Level /
          個人情報保護士
        </dd>
      </dl>
    </div>
  )
}
